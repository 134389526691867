
.landing-new-page {
    background-color: #E9E9EB;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.landing-new-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    background-color: rgba(31, 31, 31, 0.01);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
}

.landing-new-header.scrolled {
    background-color: rgba(32, 32, 42, 0.1);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
}

.landing-new-menu {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing-new-menu-logo {
    font-family: 'Forum';
    color: white;
    font-size: 18px;
}

.landing-new-menu-order {
    font-family: 'Inter';
    color: black;
    background-color: white;
    border-radius: 6px;
    border: none;
    font-size: 12px;
    padding: 7px 12px;
}

.landing-new-first {
    background-image: url('../../assets/BGDESKTOP.png');
    background-size: cover;
    height: 100vh;
    padding-top: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
}

.landing-new-first-title {
    color: white;
    font-size: 30px;
    width: 50%;
    font-family: 'Forum';
}

.landing-new-first-text {
    color: white;
    font-size: 12px;
    font-family: 'Inter';
    width: 60%;
    font-weight: 300;
    margin-bottom: 20px;
}

.landing-new-first-order {
    font-family: 'Inter';
    color: black;
    background-color: white;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
}


.landing-new-show {
    margin-top: 100px;
    padding : 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui-title {
    color: black;
    font-size: 36px;
    font-family: 'Forum';
    line-height: 1;
    margin-bottom: 20px;
}

.ui-text {
    color: black;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 20px;
}

.ui-preview {
    border-radius: 6px;
    padding: 26px;
    background-color: #131927;
    margin-bottom: 20px;
    min-height: 260px;
    display: flex;
    justify-content: center;
}

.ui-preview-itself {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.ui-preview-info.answer {
    font-family: 'Playfair';
    color: black;
    display: flex;
    flex-direction: column;
}

.ui-preview-info.answer.q {
    font-size: 6px;
    margin-bottom: 10px;
}

.ui-preview-info.answer.a {
    font-size: 12px;
    line-height: 1;
}

.ui-preview-itself.answer {
    width: 150px;
    height: 234px;
    background-color: white;
    justify-content: space-between;
}

.ui-preview-info.photo {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
}

.ui-preview-info.photo.d {
    font-size: 6px;
    margin-bottom: 5px;
}

.ui-preview-info.photo.d2 {
    font-size: 6px;
    line-height: 1;
    font-style: italic;
}

.ui-preview-itself.photo {
    width: 150px;
    height: 234px;
    /* background: url('../../assets/newlandingmobilebg.png'); */
    justify-content: space-between;
    padding-top: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.ui-preview-footer.photo {
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: 'Playfair';
    font-size: 6px;
}

.ui-preview-footer.answer {
    display: flex;
    justify-content: space-between;
    color: black;
    font-family: 'Playfair';
    font-size: 6px;
}

.ui-preview-itself.cover {
    width: 150px;
    height: 234px;
    position: relative;
}

.ui-preview-info.cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    z-index: 2;
    color: black;
    text-align: center;
}

.ui-preview-info.cover-n {
    font-size: 8px;
    font-family: 'Playfair';
    width: 60%
}

.ui-preview-info.cover-a {
    font-size: 5px;
    font-family: 'Inter';
    padding-top: 40px;
}

.ui-preview-itself-cover {
    width: 150px;
    height: 234px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.ui1 {
    border-radius: 10px;
    padding: 26px;
    background-color: #d4e3f9;
    width: 500px;
}

.ui1-info {
    border-radius: 10px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(42,52,76,1) 0%, rgba(34,38,47,1) 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ui-label {
    color: white;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 10px;
}

.ui-input, .ui-select {
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 8px;
    padding: 3px 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    height: 40px;
}

.ui-select1 {
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 8px;
    padding: 3px 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    height: 40px;
}

.ui-input:focus {
    outline: none;
}

.ui-select:focus {
    outline: none;
}

.ui-select1:focus {
    outline: none;
}

.ui-input::placeholder {
    color: #c5c5c5;
}

.ui2 {
    border-radius: 10px;
    padding: 26px;
    background-color: #d4e3f9;
    width: 500px;
}

.ui2-info {
    display: flex;
    flex-direction: column;
}

.ui2-top {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 26px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(90deg, rgba(42,52,76,1) 0%, rgba(34,38,47,1) 100%); 
    margin-bottom: 20px;
}

.ui-question {
    font-size: 14px;
    color: white;
    font-family: 'Inter';
}

.ui2-bottom {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 26px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
}

.ui-label2 {
    color: black;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 10px;
}

.ui-textarea {
    padding: 0;
    border: none;
    color: black;
    font-size: 14px;
    font-family: 'Inter';
    height: 130px
}

.ui-textarea:focus {
    outline: none;
}

.ui-textarea-photo {
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    height: 130px;
    font-family: 'Inter';
}

.ui-textarea-photo::placeholder {
    font-size: 14px;
    color: #c5c5c5;
    font-family: 'Inter';
}

.ui-textarea-photo:focus {
    outline: none;
}

.ui-upload {
    background-color: #4B5464;
    padding: 10px;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    font-family: 'Inter';
}

.ui-recipient-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    margin-top: 5px;
}

.ui-recipient-list button {
    border: 0.5px solid #c5c5c5;
    background-color: transparent;
    padding: 7px;
    width: 100%;
    font-size: 14px;
    color: #c5c5c5;
    border-radius: 6px;
}

.ui-recipient-list button.selected {
    border: 0.5px solid #ffffff;
    color: black;
    background-color: white;
    font-weight: 700;
}

.landing-new-result {
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.qa-answer {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #CDCDCD;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-family: 'Inter';
    color: black;
    margin-bottom: 30px;
    min-height: 70px;
}

.landing-new-footer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgba(19,25,39,1) 0%, rgba(20,27,39,1) 100%);
    padding: 0 20px;
    margin-top: 100px;
    text-align: center;
}

.landing-new-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 20px;
    text-align: center; 
    background-color: rgb(241, 241, 241);
}

.landing-new-video-title {
    color: black;
    font-size: 30px;
    font-family: 'Forum';
    margin-bottom: 20px;
}

.landing-new-video-text {
    color: black;
    font-family: 'Inter';
    margin-bottom: 20px;
    width: 70%;
}

.landing-new-video-order {
    font-family: 'Inter';
    color: white;
    background: linear-gradient(90deg, rgba(19,25,39,1) 0%, rgba(20,27,39,1) 100%);
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
}

.landing-new-video-prices {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
}

.landing-new-video-current {
    font-size: 18px;
    font-family: 'Inter';
    color: white;
    background-color: rgb(74, 105, 177);
    border-radius: 18px;
    padding: 8px 14px;
    font-weight: 700;
}

.landing-new-video-old {
    font-size: 18px;
    font-family: 'Inter';
    color: black;
    text-decoration: line-through
}

.video-book {
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-bottom: 30px;
    border-radius: 8px;
}

.footer-preview {
    position: relative;
    display: flex;
    justify-content: center;

}

.footer-book {
    width: 30%;
    object-fit: cover;
    margin-bottom: 80px;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

.footer-preview-info {
    z-index: 2;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 14%;
}

.footer-preview-info-b{
    font-size: 14px;
    color: black;
    font-family: 'Playfair';
    margin-bottom: 50px;
}

.footer-preview-info-a{
    font-size: 7px;
    color: black;
    font-family: 'Inter';
}

.landing-new-footer-title {
    color: white;
    font-size: 30px;
    font-family: 'Forum';
    margin-bottom: 40px;
}

.landing-new-footer-order {
    font-family: 'Inter';
    color: black;
    background-color: white;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
}

.q-title {
    color: black;
    font-size: 36px;
    font-family: 'Forum';
    line-height: 1;
    margin-bottom: 40px;
    text-align: center;
}

.r-title {
    color: black;
    font-size: 36px;
    font-family: 'Forum';
    line-height: 1;
    margin-bottom: 20px;
    text-align: center;
}

.r-text {
    color: black;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 40px;
    text-align: center;
}

.resultimg {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 6px;
}

.landing-new-tiktoks {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.landing-new-tiktok img {
    width: 100%;
    height: 650px;
    object-fit: cover;
    border-radius: 6px;
}

.landing-new-tiktok {
    padding: 7px;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
}

.landing-new-reviews {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    margin-top: 40px;
    width: 95vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.landing-new-reviews::-webkit-scrollbar {
    display: none;
}

.landing-new-review {
    border-radius: 6px;
    border: 1px solid #CDCDCD;
    background-color: rgb(241, 241, 241);
    padding: 16px;
    color: black;
    font-size: 14px;
    font-family: 'Inter';
    max-width: 260px;
    min-height: 100px;
    text-align: left;
    flex: 0 0 auto;
}

.landing-new-menu-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.landing-new-menu-ham {
    background-color: white;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px;
}

.landing-new-menu-ham svg {
    color: black;
    width: 20px;
    height: 20px;
}

.landing-new-review-instagram {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: black;
    font-family: 'Inter';
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    margin-top: 20px;
}

.navigation {
    height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

.navigation button {
    display: flex;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    font-family: 'Inter';
    padding-left: 20px;
}

.navigation a {
    color: white;
    font-size: 20px;
    font-family: 'Inter';
    padding-left: 20px;
}
  
  .navigation.show {
    height: 200px; 
    padding-top: 10px;
  }
  
  .navigation.hide {
    height: 0;
    padding-top: 0;
  }

@media (max-width: 900px) {

.landing-new-page {
    background-color: #E9E9EB;
    position: absolute;
    top: 0;
    left: 0;
    width: unset;
}

.landing-new-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    background-color: rgba(31, 31, 31, 0.01);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
}

.landing-new-header.scrolled {
    background-color: rgba(32, 32, 42, 0.4);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
}

.landing-new-menu {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing-new-menu-logo {
    font-family: 'Forum';
    color: white;
    font-size: 18px;
}

.landing-new-menu-order {
    font-family: 'Inter';
    color: black;
    background-color: white;
    border-radius: 6px;
    border: none;
    font-size: 12px;
    padding: 7px 12px;
}

.landing-new-first {
    background-image: url('../../assets/newlandingmobilebg.png');
    background-size: cover;
    height: 100vh;
    padding-top: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
}

.landing-new-first-title {
    color: white;
    font-size: 30px;
    width: 50%;
    font-family: 'Forum';
}

.landing-new-first-text {
    color: white;
    font-size: 12px;
    font-family: 'Inter';
    width: 60%;
    font-weight: 300;
    margin-bottom: 20px;
}

.landing-new-first-order {
    font-family: 'Inter';
    color: black;
    background-color: white;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
}


.landing-new-show {
    margin-top: 100px;
    padding : 0 20px;
    display: flex;
    flex-direction: column;
    align-items: unset;
}

.ui-title {
    color: black;
    font-size: 36px;
    font-family: 'Forum';
    line-height: 1;
    margin-bottom: 20px;
}

.ui-text {
    color: black;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 20px;
}

.ui-preview {
    border-radius: 6px;
    padding: 26px;
    background-color: #131927;
    margin-bottom: 20px;
    min-height: 260px;
    display: flex;
    justify-content: center;
}

.ui-preview-itself {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.ui-preview-info.answer {
    font-family: 'Playfair';
    color: black;
    display: flex;
    flex-direction: column;
}

.ui-preview-info.answer.q {
    font-size: 6px;
    margin-bottom: 10px;
}

.ui-preview-info.answer.a {
    font-size: 12px;
    line-height: 1;
}

.ui-preview-itself.answer {
    width: 150px;
    height: 234px;
    background-color: white;
    justify-content: space-between;
}

.ui-preview-info.photo {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
}

.ui-preview-info.photo.d {
    font-size: 6px;
    margin-bottom: 5px;
}

.ui-preview-info.photo.d2 {
    font-size: 6px;
    line-height: 1;
    font-style: italic;
}

.ui-preview-itself.photo {
    width: 150px;
    height: 234px;
    /* background: url('../../assets/newlandingmobilebg.png'); */
    justify-content: space-between;
    padding-top: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.ui-preview-footer.photo {
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: 'Playfair';
    font-size: 6px;
}

.ui-preview-footer.answer {
    display: flex;
    justify-content: space-between;
    color: black;
    font-family: 'Playfair';
    font-size: 6px;
}

.ui-preview-itself.cover {
    width: 150px;
    height: 234px;
    position: relative;
}

.ui-preview-info.cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    z-index: 2;
    color: black;
    text-align: center;
}

.ui-preview-info.cover-n {
    font-size: 8px;
    font-family: 'Playfair';
    width: 60%
}

.ui-preview-info.cover-a {
    font-size: 5px;
    font-family: 'Inter';
    padding-top: 40px;
}

.ui-preview-itself-cover {
    width: 150px;
    height: 234px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.ui1 {
    border-radius: 10px;
    padding: 26px;
    background-color: #D9D9D9;
    width: unset;
}

.ui1-info {
    border-radius: 10px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(42,52,76,1) 0%, rgba(34,38,47,1) 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ui-label {
    color: white;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 10px;
}

.ui-input, .ui-select {
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 8px;
    padding: 3px 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    height: 40px;
}

.ui-select1 {
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 8px;
    padding: 3px 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    height: 40px;
}

.ui-input:focus {
    outline: none;
}

.ui-select:focus {
    outline: none;
}

.ui-select1:focus {
    outline: none;
}

.ui-input::placeholder {
    color: #c5c5c5;
}

.ui2 {
    border-radius: 10px;
    padding: 26px;
    background-color: #D9D9D9;
    width: unset;
}

.ui2-info {
    display: flex;
    flex-direction: column;
}

.ui2-top {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 26px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(90deg, rgba(42,52,76,1) 0%, rgba(34,38,47,1) 100%); 
    margin-bottom: 20px;
}

.ui-question {
    font-size: 14px;
    color: white;
    font-family: 'Inter';
}

.ui2-bottom {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 26px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
}

.ui-label2 {
    color: black;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 10px;
}

.ui-textarea {
    padding: 0;
    border: none;
    color: black;
    font-size: 14px;
    font-family: 'Inter';
    height: 130px
}

.ui-textarea:focus {
    outline: none;
}

.ui-textarea-photo {
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid white;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    height: 130px;
    font-family: 'Inter';
}

.ui-textarea-photo::placeholder {
    font-size: 14px;
    color: #c5c5c5;
    font-family: 'Inter';
}

.ui-textarea-photo:focus {
    outline: none;
}

.ui-upload {
    background-color: #4B5464;
    padding: 10px;
    font-size: 14px;
    color: white;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    font-family: 'Inter';
}

.ui-recipient-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    margin-top: 5px;
}

.ui-recipient-list button {
    border: 0.5px solid #c5c5c5;
    background-color: transparent;
    padding: 7px;
    width: 100%;
    font-size: 14px;
    color: #c5c5c5;
    border-radius: 6px;
}

.ui-recipient-list button.selected {
    border: 0.5px solid #ffffff;
    color: black;
    background-color: white;
    font-weight: 700;
}

.landing-new-result {
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.qa-answer {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #CDCDCD;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-family: 'Inter';
    color: black;
    margin-bottom: 30px;
    min-height: 70px;
}

.landing-new-footer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgba(19,25,39,1) 0%, rgba(20,27,39,1) 100%);
    padding: 0 20px;
    margin-top: 100px;
    text-align: center;
}

.landing-new-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 20px;
    text-align: center; 
    background-color: rgb(241, 241, 241);
}

.landing-new-video-title {
    color: black;
    font-size: 30px;
    font-family: 'Forum';
    margin-bottom: 20px;
}

.landing-new-video-text {
    color: black;
    font-family: 'Inter';
    margin-bottom: 20px;
    width: 70%;
}

.landing-new-video-order {
    font-family: 'Inter';
    color: white;
    background: linear-gradient(90deg, rgba(19,25,39,1) 0%, rgba(20,27,39,1) 100%);
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
}

.landing-new-video-prices {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
}

.landing-new-video-current {
    font-size: 18px;
    font-family: 'Inter';
    color: white;
    background-color: rgb(74, 105, 177);
    border-radius: 18px;
    padding: 8px 14px;
    font-weight: 700;
}

.landing-new-video-old {
    font-size: 18px;
    font-family: 'Inter';
    color: black;
    text-decoration: line-through
}

.video-book {
    width: 60%;
    height: 400px;
    object-fit: cover;
    margin-bottom: 30px;
    border-radius: 8px;
}

.footer-preview {
    position: relative;
    display: flex;
    justify-content: center;

}

.footer-book {
    width: 60%;
    object-fit: cover;
    margin-bottom: 80px;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

.footer-preview-info {
    z-index: 2;
    position: absolute;
    text-align: center;
    top: 140px;
    width: 27%;
}

.footer-preview-info-b{
    font-size: 14px;
    color: black;
    font-family: 'Playfair';
    margin-bottom: 50px;
}

.footer-preview-info-a{
    font-size: 7px;
    color: black;
    font-family: 'Inter';
}

.landing-new-footer-title {
    color: white;
    font-size: 30px;
    font-family: 'Forum';
    margin-bottom: 40px;
}

.landing-new-footer-order {
    font-family: 'Inter';
    color: black;
    background-color: white;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 14px 20px;
}

.q-title {
    color: black;
    font-size: 36px;
    font-family: 'Forum';
    line-height: 1;
    margin-bottom: 40px;
    text-align: center;
}

.r-title {
    color: black;
    font-size: 36px;
    font-family: 'Forum';
    line-height: 1;
    margin-bottom: 20px;
    text-align: center;
}

.r-text {
    color: black;
    font-size: 12px;
    font-family: 'Inter';
    margin-bottom: 40px;
    text-align: center;
}

.resultimg {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 6px;
}

.landing-new-tiktoks {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.landing-new-tiktok img {
    width: 100%;
    height: 190px;
    object-fit: cover;
    border-radius: 6px;
}

.landing-new-tiktok {
    padding: 7px;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
}

.landing-new-reviews {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    margin-top: 40px;
    width: 90vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.landing-new-reviews::-webkit-scrollbar {
    display: none;
}

.landing-new-review {
    border-radius: 6px;
    border: 1px solid #CDCDCD;
    background-color: rgb(241, 241, 241);
    padding: 16px;
    color: black;
    font-size: 14px;
    font-family: 'Inter';
    max-width: 260px;
    min-height: 100px;
    text-align: left;
    flex: 0 0 auto;
}
}

@media (max-height: 650px) {
    .footer-preview-info {
        top: 130px;
    }

    .footer-preview-info-b{
        font-size: 12px;
    }
    
    .footer-preview-info-a{
        font-size: 8px;
    }
}